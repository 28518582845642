<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 p-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end" />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSlaugterhouseListTable"
        :responsive="true"
        :items="filteredSlaugterhouses"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page.sync="currentPage"
      >
        <!-- Column: Slaugterhouse -->

        <template #cell(animalid)="data">
          <b-link
            :to="{
              name: 'animal-actions',
              query: {
                id: data.item.animalid,
              },
            }"
          >
            <span class="align-middle ml-50 text d-flex align-items-center">
              <feather-icon icon="EyeIcon" class="mr-1 text-muted" />
              {{ data.item.earingno }}
            </span>
          </b-link>
        </template>
        <template #cell(delete)="data">
          <b-link
            @click="
              deleteSlaugterhouse({
                id: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredSlaugterhouses.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink, BPagination } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useSlaugterhouseList from "./useSlaugterhouseList";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  props: {},
  mounted() {},

  beforeRouteEnter(to, from, next) {
    if (to.name == "slaugterhouses") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("slaugterhouses"),
        active: true,
      });
    }
    next();
  },
  setup(props, { emit }) {
    const isAddNewSlaugterhouseSidebarActive = ref(false);
    const isEditSlaugterhouseSidebarActive = ref(false);
    const selectedSlaugterhouse = ref({});
    const slaugterhouseData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedSlaugterhouse.value = item;
      isEditSlaugterhouseSidebarActive.value =
        !isEditSlaugterhouseSidebarActive.value;
    };

    const {
      fetchSlaugterhouses,
      tableColumns,
      perPage,
      currentPage,
      totalSlaugterhouses,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSlaugterhouseListTable,
      refetchData,
      allSlaugterhouses,
      filteredSlaugterhouses,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteSlaugterhouse,
    } = useSlaugterhouseList();

    fetchSlaugterhouses();

    return {
      // Sidebar
      isAddNewSlaugterhouseSidebarActive,
      isEditSlaugterhouseSidebarActive,
      openEditSideBar,

      selectedSlaugterhouse,

      fetchSlaugterhouses,
      tableColumns,
      perPage,
      currentPage,
      totalSlaugterhouses,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSlaugterhouseListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI
      allSlaugterhouses,
      filteredSlaugterhouses,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteSlaugterhouse,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
