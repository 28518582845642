<template>
  <div>
    <animal-list-slaugterhouses />
  </div>
</template>

<script>
import AnimalListSlaugterhouses from "./components/AnimalSlaugterhouses/AnimalSlaugterhousesList.vue";
import { mapState } from "vuex";
export default {
  components: { AnimalListSlaugterhouses },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
};
</script>

<style></style>
