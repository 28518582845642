var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0 p-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("eachPage")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v(" "+_vm._s(_vm.$t("piece"))+" ")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"})])],1)],1),_c('b-table',{ref:"refSlaugterhouseListTable",attrs:{"responsive":true,"items":_vm.filteredSlaugterhouses,"fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc,"per-page":_vm.perPage,"current-page":_vm.currentPage},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"cell(animalid)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'animal-actions',
            query: {
              id: data.item.animalid,
            },
          }}},[_c('span',{staticClass:"align-middle ml-50 text d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-1 text-muted",attrs:{"icon":"EyeIcon"}}),_vm._v(" "+_vm._s(data.item.earingno)+" ")],1)])]}},{key:"cell(delete)",fn:function(data){return [_c('b-link',{on:{"click":function($event){return _vm.deleteSlaugterhouse({
              id: data.item.id,
            })}}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('small',{staticClass:"align-middle ml-50 text-dark"},[_vm._v(_vm._s(_vm.$t("delete")))])],1)])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("showing"))+" "+_vm._s(_vm.dataMeta.from)+" "+_vm._s(_vm.$t("to"))+" "+_vm._s(_vm.dataMeta.to)+" "+_vm._s(_vm.$t("of"))+" "+_vm._s(_vm.dataMeta.of)+" "+_vm._s(_vm.$t("entries"))+" ")])])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.filteredSlaugterhouses.length,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }